// import prodConfig from './app.config.prod'
// import devConfig from './app.config.dev'
import envConfig from 'configFile'

// Static config that should be served from BE in the future and used across whole app
const appConfig = {
  auth: {
    tokenHeaderKey: 'auth-token',
  },
  localStoragePrefix: 'cdbl',
  layout: {
    newLayoutStates: [
      'issues',
      'elements',
      'dashboard',
      'settings-templates-intercom',
      'settings-templates-comment',
      'expertPods',
      'projects',
      'growthTargets',
      'settings-account',
      'auth.login',
      'settings-superusers',
      'settings-unlayer-templates'
    ]
  },
  pusherChannels: {
    issues: 'private-admin-issues',
    issue: 'private-admin-issues-[id]'
  },
  issueSystem: {
    // All pusher events that are allowed
    pusherIssueAllowedEvents: [
      // issue create
      'issue-opened',

      // issue update
      'issue-reopened',
      'issue-assigned',
      'issue-resolved',
      'issue-unassigned',
      'issue-reassigned',
      'issue-condition-cleared',
      'issue-snoozed',
      'issue-unsnoozed',

      // issue note
      'issue-note-added'
    ],

    // Pusher events to create new issue in global channel
    pusherIssueCreatedEvents: [
      'issue-opened'
    ],

    // Pusher events to update issue in global or issue-id channel
    pusherIssueUpdatedEvents: [
      'issue-reopened',
      'issue-assigned',
      'issue-resolved',
      'issue-unassigned',
      'issue-reassigned',
      'issue-condition-cleared',
      'issue-snoozed',
      'issue-unsnoozed',
    ],

    // Pusher events to update issue resource in issue-id channel
    pusherIssueResourceUpdatedEvents: [
      'issue-resource-updated',
    ],

    issueToolbarSearch: {
      fields: {
        type: {
          name: 'Type',
          options: [
            {
              id: 'project_quality_review_escalation',
              name: 'Project Quality Review Escalation'
            },
            {
              id: 'project_quality_review_rejection',
              name: 'Project Quality Review Rejection'
            },
            {
              id: 'incident',
              name: 'Incident'
            },
            {
              id: 'opportunity_management',
              name: 'Opportunity Management'
            },
            {
              id: 'project_cancellation',
              name: 'Project Cancellation'
            },
            {
              id: 'project_feedback',
              name: 'Project Feedback'
            },
            {
              id: 'client_gone_unresponsive',
              name: 'Client Gone Unresponsive'
            },
            {
              id: 'expert_attention_needed',
              name: 'Expert Attention Needed'
            },
            {
              id: 'payment_failure',
              name: 'Payment Failure'
            },
            {
              id: 'unresponsive_expert',
              name: 'Unresponsive Expert'
            },
            {
              id: 'unresponsive_expert_pod',
              name: 'Unresponsive Expert Pod'
            },
            {
              id: 'unresponsive_expert_pod',
              name: 'Unresponsive Expert Pod'
            },
            {
              id: 'project_quality_assessement',
              name: 'Project Quality Assessment'
            },
            {
              id: 'duplicates',
              name: 'Duplicates'
            },
            {
              id: 'expert_milestone_first_project',
              name: 'Expert Milestone: 1st Project'
            },
            {
              id: 'expert_milestone_hundredth_project',
              name: 'Expert Milestone: 100th Project'
            },
            {
              id: 'max_open_projects',
              name: 'Expert Reached Max Open Projects'
            },
            {
              id: 'abandoned_project',
              name: 'Zombie Project'
            },
            {
              id: 'npc_promotion',
              name: 'NPC Needs Help'
            },
            {
              id: 'no_scope_doc_on_paid_project',
              name: 'No Scope Doc On Paid Project'
            },
            {
              id: 'experts_teams_details_updated',
              name: 'Expert team details updated'
            },
            {
              id: 'experts_team_details_not_confirmed',
              name: 'Expert team details not confirmed'
            },
            {
              id: 'poor_review',
              name: 'Less than 4 * Review'
            },
            {
              id: 'undesirable_client_posted_project',
              name: 'Undesirable Client Posted Project'
            }
          ]
        }
      }
    },
    issueViewTabs: {
      defaultTab: 'notes',
      tabs: [
        {
          id: 'notes',
          title: 'Notes'
        },
        {
          id: 'history',
          title: 'History'
        }
      ]
    }
  },
  templateTokens: {
    tokenList: [
      // Intercom
      {
        name: 'first_name',
        argumentName: 'fallback',
        argumentDefaultValue: 'there',
        type: 'intercom',
        context: ['intercom-user'],
        description: 'Generates recepient first name. Gets replaced by Intercom.'
      },

      // Others
      {
        name: 'cdbl_author_name',
        type: 'codeable',
        context: ['author'],
        description: 'Generates full name of a user selected in \'From\' field.'
      },


      // Current (logged in) user in admin
      {
        name: 'cdbl_current_user_first_name',
        type: 'codeable',
        context: ['current-user'],
        description: 'Generates first name of a currently logged in user in admin.'
      },

      {
        name: 'cdbl_current_user_full_name',
        type: 'codeable',
        context: ['current-user'],
        description: 'Generates full name of a currently logged in user in admin.'
      },

      // Task
      {
        name: 'cdbl_task_id',
        type: 'codeable',
        context: ['task'],
        description: 'Generates current task id.'
      },

      {
        name: 'cdbl_task_url',
        type: 'codeable',
        context: ['task'],
        description: 'Generates current task URL (href).'
      },

      {
        name: 'cdbl_task_link',
        type: 'codeable',
        context: ['task'],
        description: 'Generates current task link as HTML element.'
      },

      {
        name: 'cdbl_task_title',
        type: 'codeable',
        context: ['task'],
        description: 'Generates current task title.'
      },

      // Client
      {
        name: 'cdbl_client_first_name',
        type: 'codeable',
        context: ['client'],
        description: 'Generates client first name.'
      },

      {
        name: 'cdbl_client_full_name',
        type: 'codeable',
        context: ['client'],
        description: 'Generates client full name.'
      },

      // Expert
      {
        name: 'cdbl_expert_first_name',
        type: 'codeable',
        context: ['expert'],
        description: 'Generates expert first name.'
      },

      {
        name: 'cdbl_expert_full_name',
        type: 'codeable',
        context: ['expert'],
        description: 'Generates expert full name.'
      },

      {
        name: 'cdbl_workroom_url',
        type: 'codeable',
        context: ['expert'],
        description: 'Generates workroom url.'
      }
    ],

    // TODO: move intercom tokens to same level system
    // TODO: find universal token definition instead of simple/complex, define token pattern
    complex: [
      {
        name: 'first_name',
        argumentName: 'fallback',
        argumentDefaultValue: 'there',
        type: 'intercom',
        context: ['intercom'],
        description: 'Generates recepient first name. Gets replaced by Intercom.'
      }
    ],
    simple: [
      // All
      {
        name: 'cdbl_author_name',
        type: 'codeable',
        context: ['current-user'],
        description: 'Generates full name of a user selected in \'To\' field.'
      },

      // Task
      {
        name: 'cdbl_task_id',
        type: 'codeable',
        context: ['task'],
        description: 'Generates current task id.'
      },

      {
        name: 'cdbl_task_url',
        type: 'codeable',
        context: ['task'],
        description: 'Generates current task URL (href).'
      },

      {
        name: 'cdbl_task_link',
        type: 'codeable',
        context: ['task'],
        description: 'Generates current task link as HTML element.'
      },

      {
        name: 'cdbl_task_title',
        type: 'codeable',
        context: ['task'],
        description: 'Generates current task title.'
      }
    ]
  },
  navigation: {
    loginPage: {
      sref: 'auth.login'
    },
    defaultPage: {
      sref: 'issueList',
      params: { folderId: 'all', subFolderId: 'unassigned' }
    },
    sidebar: [
      {
        sref: `issueList({ folderId: 'all', subFolderId: 'unassigned' })`,
        title: 'Action Items'
      },
      {
        header: 'Overview'
      },
      {
        sref: 'growthTargets',
        title: 'Growth Targets'
      },
      {
        sref: 'watchlist',
        title: 'Watchlist'
      },
      {
        sref: 'alerts',
        title: 'Project Alerts'
      },
      {
        sref: 'kpis.global',
        title: 'Global KPIs'
      },
      {
        sref: 'kpis.users',
        title: 'User KPIs'
      },
      {
        sref: 'kpis.tasks',
        title: 'Project Performance'
      },

      {
        sref: 'tags.all',
        title: 'Tags'
      },
      {
        header: 'Resources'
      },
      {
        sref: 'tasks.state({state: \'all\'})',
        title: 'Projects'
      },
      {
        sref: 'tasks.preferred',
        title: 'Preferred Projects'
      },
      // {
      //   sref: 'projects.projectsList({ withExpertPods: true })',
      //   title: 'Expert Pods Projects'
      // },
      {
        sref: 'subTasksRetainers',
        title: 'Retainer Tasks'
      },
      {
        sref: 'expertPods.podList',
        title: 'Expert Pods'
      },
      {
        sref: 'users.role({role: \'contractor\'})',
        title: 'Experts'
      },
      {
        sref: 'inactive_contractors',
        title: 'Inactive experts'
      },
      {
        sref: 'users.role({role: \'client\'})',
        title: 'Clients'
      },
      {
        sref: 'reviews',
        title: 'Reviews'
      },
      {
        header: 'Accounting'
      },
      {
        sref: 'transactions',
        title: 'Transactions'
      },
      {
        sref: 'withdrawals',
        title: 'Withdrawal Requests'
      },
      {
        header: 'Settings'
      },
      {
        sref: 'settings-templates-comment',
        title: 'Comment Templates'
      },
      {
        sref: 'settings.emailTemplates',
        title: 'Email Templates'
      },
      {
        sref: 'settings-templates-intercom',
        title: 'Intercom Templates'
      },
      {
        sref: 'settings-account',
        title: 'My Account'
      },
      {
        header: 'Admin',
        permission: 'superusers',
      },
      {
        sref: 'settings-superusers',
        title: 'User management',
        permission: 'superusers',
      },
      {
        sref: 'settings-unlayer-templates',
        title: 'Unlayer Templates',
        permission: 'unlayerTemplates',
      }
    ]
  }
}

const CONFIG = {
  ...appConfig,
  ...envConfig,
  ...APP_ENV
}

// Override api
if (APP_ENV.CDBL_LOCAL_API) {
  CONFIG.apiUrl = APP_ENV.CDBL_LOCAL_API
}

// if (APP_ENV.isProd) {
//   CONFIG = {
//     ...appConfig,
//     ...prodConfig
//   }
// } else {
//   CONFIG = {
//     ...appConfig,
//     ...devConfig
//   }
// }

export default CONFIG
