// Static config that should be served from BE in the future and used across whole app

const config = {
  isDevelopment: true,
  apiUrl: 'https://api.staging.codeable.io',
  appUrl: 'https://staging.codeable.io',
  settings: {
    tokenRenewalInterval: 21600
  },
  referooApiUrl: 'https://api.staging.referoo.co',
  // Pusher key and auth endpoint are same as app
  pusher: {
    appKey: '2276a24d17bae754d49b',
    authEndpoint: '/admin/pusher/auth'
  },
  intercom: {
    appId: 'gn86bh8e'
  },
  sentry: {
    dns: 'https://38424c273cb94b8e922d493aa93d97f0@o26974.ingest.sentry.io/5444791',
  }
}
export default config
